import React, { useState } from "react";
import { Alert, Radio, RadioChangeEvent } from "antd";

interface IBooleanQuestionProps {
  question: string | JSX.Element;
  fieldName?: string;
  answer?: boolean | null;
  handleChange: (v: any) => void;
  correctAnswer?: boolean;
}

export const BooleanQuestion = ({
  handleChange,
  fieldName,
  question,
  answer,
  correctAnswer,
}: IBooleanQuestionProps) => {
  const [value, setValue] = useState(answer);

  const onChange = (e: RadioChangeEvent) => {
    setValue(e.target.value);
    handleChange(e);
  };

  const isAnswerCorrect = () => {
    return !(typeof value === "boolean" && value !== correctAnswer);
  };

  return (
    <div className="space-y-2">
      <div className="text-left">{question}</div>
      <div>
        <Radio.Group onChange={onChange} name={fieldName} value={value}>
          <Radio value={true}>Yes</Radio>
          <Radio value={false}>No</Radio>
        </Radio.Group>
      </div>
      {typeof correctAnswer === "boolean" && !isAnswerCorrect() ? (
        <Alert
          message="This option may or will lead to failed verification."
          type="warning"
          showIcon
        />
      ) : null}
    </div>
  );
};
