import React from "react";

interface ISidebarTopProps {
  name: string;
  email: string;
}
const SidebarTop = ({ name = "", email = "" }: ISidebarTopProps) => {
  return (
    <div className="w-full pb-3 pt-5 px-7 space-y-2 sidebar-top-container">
      <div className="space-y-1">
        <p className="text-sm font-semobold text-black">{name}</p>
        <p className="text-xs text-black opacity-70">{email}</p>
      </div>
      <div>
        <p className="p-2 bg-primary-blue-light text-primary-blue inline-block rounded-md font-medium text-xs">
          Verification Officer
        </p>
      </div>
    </div>
  );
};

export default SidebarTop;
