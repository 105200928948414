// WALLET
export const WALLET_TRANSACTIONS_FETCHED = "WALLET_TRANSACTIONS_FETCHED";
export const IS_FETCHING_WALLET_TRANSACTIONS =
  "IS_FETCHING_WALLET_TRANSACTIONS";
export const WALLET_BALANCE_FETCHED = "WALLET_BALANCE_FETCHED";
export const IS_FETCHING_WALLET_BALANCE = "IS_FETCHING_WALLET_BALANCE";
export const WALLET_CASHOUT_PROCESSED = "WALLET_CASHOUT_PROCESSED";
export const IS_PROCESSING_WALLET_CASHOUT = "IS_PROCESSING_WALLET_CASHOUT";
export const WALLET_CASHOUT_PROCESS_RESET = "WALLET_CASHOUT_PROCESS_RESET";
export const WALLET_CASHOUT_ACCOUNT_FETCHED = "WALLET_CASHOUT_ACCOUNT_FETCHED";
export const IS_FETCHING_WALLET_CASHOUT_ACCOUNT =
  "IS_FETCHING_WALLET_CASHOUT_ACCOUNT";
