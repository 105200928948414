import React from "react";
import { Router, Switch } from "react-router";
import Routes from "./common/components/Routes";
import history from "./common/config/history";
import "./App.less";

const App = () => (
  <Router history={history}>
    <Switch>
      <Routes />
    </Switch>
  </Router>
);

export default App;
