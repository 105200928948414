import React from "react";
import { Button, Modal } from "antd";
import useMobile from "../../../../common/utils/hooks/useMobile";
import failure from "../../../../static/images/failed.svg";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../../../common/types";

interface IProps {
  isModalVisible: boolean;
  handleRetry: () => void;
  closeModal: () => void;
  locationValidationFailedErrorMsg: string | undefined;
}
const VerificationLocationFailedModal = ({
  isModalVisible,
  handleRetry,
  closeModal,
  locationValidationFailedErrorMsg,
}: IProps) => {
  const isMobile = useMobile();
  const locationAddress = useSelector(
    (store: ReduxStoreModel) =>
      store.verificationLocationValidation.verificationAddress
  );
  return (
    <Modal
      centered
      open={isModalVisible}
      onCancel={closeModal}
      closable={false}
      width={isMobile ? "100vw" : "30vw"}
      footer={
        <div className="flex flex-row justify-end">
          <Button
            id="btn__small"
            type="default"
            size="middle"
            danger
            onClick={closeModal}
            className="btn__small"
          >
            Go back
          </Button>
          <Button
            id="btn__small"
            type="primary"
            size="middle"
            className="mr-3"
            onClick={handleRetry}
          >
            Retry
          </Button>
        </div>
      }
      style={{
        borderRadius: "6px",
        overflow: "hidden",
        paddingBottom: 0,
        backgroundColor: "#fff",
      }}
    >
      <br />
      <div className="flex justify-center">
        <img src={failure} className="max-w-full" alt="livenesscheck failed" />
      </div>
      <p className="text-center my-5 font-bold">Location Validation Failed</p>{" "}
      {locationValidationFailedErrorMsg ? (
        <p className="text-center my-5">{locationValidationFailedErrorMsg}</p>
      ) : (
        <p className="text-center my-5">
          You need to be at <span className="font-bold">{locationAddress}</span>{" "}
          to continue
        </p>
      )}
      <br />
    </Modal>
  );
};

export default VerificationLocationFailedModal;
