import { USE_MOCK } from "common/config/constants";
import { generateErrorMessage } from "modules/auth/actions";
import { makeAuthorizedRequestWithHeadersAndPayload } from ".";
import { mockPDFBase64 } from "../../../modules/verificationTasks/pendingTasks/service/mock";

export const getFromFileService = async (fileRef: string) => {
  if (USE_MOCK) {
    return mockPDFBase64;
  }
  return await makeAuthorizedRequestWithHeadersAndPayload(
    "GET",
    `/files/download/${fileRef}`
  ).catch(generateErrorMessage);
};
