import axios, { AxiosInstance } from "axios";
import jwtDecode, { JwtPayload } from "jwt-decode";
import { API_URL, REQUEST_TIMEOUT, UMS_API_URL } from "../../config/constants";
import history from "../../config/history";
import Language from "../../utils/language/en";
import { clearData, fetchUserToken } from "../storage";
import { ROUTES } from "../../components/Routes/types";

/** general headers **/
const headers: any = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

/** authorization header for logged in user **/
const setAuthorization = (): { Authorization: string } => {
  let token = fetchUserToken();
  let decoded: JwtPayload;

  try {
    decoded = jwtDecode(String(token));
  } catch {
    return {
      Authorization: `Bearer ${null}`,
    };
  }

  if (!decoded || !decoded.exp) {
    return {
      Authorization: `Bearer ${null}`,
    };
  }

  const expiryDate = new Date(decoded.exp * 1000);
  const currentDate = new Date();

  if (expiryDate < currentDate) {
    return {
      Authorization: `Bearer ${null}`,
    };
  }

  return {
    Authorization: `Bearer ${token}`,
  };
};

/** axios instance **/
const instance: AxiosInstance = axios.create({
  baseURL: API_URL,
  headers,
});

/** timeout configuration for axios instance **/
instance.defaults.timeout = REQUEST_TIMEOUT;

/** axios interceptor to trigger a logout on unauthorized error response code **/
instance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const loginRoutes: string[] = [ROUTES.LOGIN, ROUTES.LOGIN_WITH_MONIEPOINT];
    /* TODO: remember to remove the path check*/
    if (
      error.response &&
      error.response.status === 401 &&
      !loginRoutes.includes(history.location.pathname)
    ) {
      clearData();
      window.location.reload();
      return Promise.reject({
        status: 401,
        message: "Login session expired, please login again",
      });
    }

    return Promise.reject(
      error
        ? error.response
          ? error.response.data
          : error.response
        : Language.NetworkErrorMessage.errorMessage
    );
  }
);

const umsInstance: AxiosInstance = axios.create({
  baseURL: UMS_API_URL,
  headers,
});

umsInstance.defaults.timeout = REQUEST_TIMEOUT;

umsInstance.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    /* TODO: remember to remove the path check*/
    if (
      error.response &&
      error.response.status === 401 &&
      history.location.pathname !== "/login"
    ) {
      clearData();
      window.location.reload();
      return Promise.reject({
        status: 401,
        message: "Login session expired, please login again",
      });
    }

    return Promise.reject(
      error
        ? error.response
          ? error.response.data
          : error.response
        : Language.NetworkErrorMessage.errorMessage
    );
  }
);

/** make an axios get request **/
export const makeGetRequest = (path: string): Promise<any> =>
  instance.get(path);

/** make an axios post request **/
export const makePostRequest = (path: string, payload: any): Promise<any> =>
  instance.post(path, payload);

/** make an axios request for a guest **/
export const makeUnauthorizedRequestWithHeadersAndPayload = async (
  method: any,
  url: string,
  data: any
) => {
  const response = await instance.request({
    method,
    url,
    data,
    headers,
  });
  return response;
};

/** make an axios request for logged-in user **/
export const makeAuthorizedRequestWithHeadersAndPayload = async (
  method: any,
  url: string,
  data: any = {},
  params?: any
) => {
  const response = await instance.request({
    method,
    url: url,
    data,
    params,
    headers: {
      ...headers,
      ...setAuthorization(),
    },
  });
  return response;
};

/** make an axios request to submit a file **/
export const makeRequestWithFormData = async (
  method: any,
  url: string,
  data: any,
  authorized: boolean
) => {
  /** create new formdata object **/
  let formData = new FormData();

  let headers: any = {
    "Content-Type": "multipart/form-data",
  };

  /** loop through and append all data to formdata object **/
  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      let fieldData: any = data[key];
      formData.append(key, fieldData);
    }
  }

  // add authorization header if authorization is required
  if (authorized) {
    headers = { ...headers, ...setAuthorization() };
  }

  const response = await instance.request({
    method,
    url: url,
    data: formData,
    headers,
  });

  return response;
};

export const generateErrorMessage = (error: any) => {
  if (!error) {
    return "Something went wrong";
  }
  return typeof error == "string"
    ? error
    : error.responseMessage || error.message || "Something went wrong";
};

export const makeUmsUnauthorizedRequestWithHeadersAndPayload = async (
  method: any,
  url: string,
  data: any
) => {
  return await umsInstance.request({
    method,
    url,
    data,
    headers,
  });
};
