import React from "react";
import { useDispatch } from "react-redux";
import logoutIcon from "static/images/logoutIcon.svg";
import burgerIcon from "static/images/TopNav/burger.svg";
import { logUserOut } from "../../../../../modules/auth/actions";
import ActionConfirmationModal from "../../../ActionConfirmationModal";

interface ITopNavProps {
  isSideBarOpen: boolean;
  toggleSideBarOpen(x: boolean): void;
}

const TopNav = ({ isSideBarOpen, toggleSideBarOpen }: ITopNavProps) => {
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const logout = () => {
    dispatch(logUserOut());
  };

  return (
    <section className="navigation">
      <nav className={`nav w-full z-40 flex justify-center`}>
        <div className={`sm:pl-9 pl-0`}>
          <button
            onClick={() => {
              toggleSideBarOpen(!isSideBarOpen);
            }}
            className="border-none bg-transparent outline-none"
          >
            <img src={burgerIcon} alt="menu" className="w-6 h-6" />
          </button>
        </div>
        <div className="nav__account sm:pr-6 pr-0">
          <div className="cursor" onClick={() => setIsModalVisible(true)}>
            <img src={logoutIcon} alt="logout" />
          </div>
        </div>
      </nav>
      <ActionConfirmationModal
        cancelButtonText="No, go back"
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
        modalConfirmationMessage="Are you sure you want to logout?"
        modalTitle="Logout"
        okButtonText="Yes, logout"
        onConfirmation={logout}
        okButtonColor="#CC5858"
      />
    </section>
  );
};
export default TopNav;