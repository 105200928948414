import React from "react";

const EmptyMedia = (props: { height?: number; width?: number }) => {
  return (
    <svg
      width={props.width || 64}
      height={props.height || 60}
      viewBox={`0 0 ${props.width || 64} ${props.height || 60}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="2"
        y="2"
        width="60"
        height="56"
        rx="8"
        fill="#0B3275"
        fillOpacity="0.05"
      />
      <rect
        x="1"
        y="1"
        width="62"
        height="58"
        rx="9"
        stroke="#0B3275"
        strokeOpacity="0.4"
        strokeWidth="2"
        strokeDasharray="4 4"
      />
      <path
        d="M19.8125 22.3811C19.8125 21.5728 20.1336 20.7977 20.7051 20.2261C21.2767 19.6546 22.0518 19.3335 22.8601 19.3335H41.1458C41.9541 19.3335 42.7293 19.6546 43.3008 20.2261C43.8724 20.7977 44.1935 21.5728 44.1935 22.3811V37.6192C44.1935 38.4275 43.8724 39.2027 43.3008 39.7742C42.7293 40.3457 41.9541 40.6668 41.1458 40.6668H22.8601C22.0518 40.6668 21.2767 40.3457 20.7051 39.7742C20.1336 39.2027 19.8125 38.4275 19.8125 37.6192V22.3811ZM21.3363 36.0954V37.6192C21.3363 38.0233 21.4969 38.4109 21.7826 38.6967C22.0684 38.9825 22.456 39.143 22.8601 39.143H41.1458C41.55 39.143 41.9376 38.9825 42.2233 38.6967C42.5091 38.4109 42.6696 38.0233 42.6696 37.6192V32.2859L36.9142 29.319C36.7713 29.2474 36.6095 29.2226 36.4517 29.248C36.2939 29.2735 36.1481 29.3479 36.035 29.4607L30.3816 35.1141L26.3283 32.4139C26.182 32.3164 26.0064 32.2726 25.8315 32.2898C25.6565 32.3071 25.4929 32.3843 25.3683 32.5084L21.3363 36.0954ZM28.9554 26.1906C28.9554 25.5844 28.7145 25.003 28.2859 24.5744C27.8572 24.1457 27.2759 23.9049 26.6696 23.9049C26.0634 23.9049 25.4821 24.1457 25.0534 24.5744C24.6247 25.003 24.3839 25.5844 24.3839 26.1906C24.3839 26.7968 24.6247 27.3782 25.0534 27.8069C25.4821 28.2355 26.0634 28.4764 26.6696 28.4764C27.2759 28.4764 27.8572 28.2355 28.2859 27.8069C28.7145 27.3782 28.9554 26.7968 28.9554 26.1906Z"
        fill="#0B3275"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default EmptyMedia;
