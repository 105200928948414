import { Dispatch } from "redux";
import { getVerificationLocationValidationConfig } from "../service";
import {
  FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG,
  FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG_FAILED,
  LOCATION_VALIDATION_CONFIG_FETCHED,
  UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES,
  UPDATE_VERIFICATION_LOCATION_ADDRESS,
  UPDATE_VERIFICATION_LOCATION_COORDINATES,
} from "./types";

const isFetchingVerificationLocationValidationConfig = (payload: boolean) => ({
  type: FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG,
  payload,
});

const locationValidationFetchSuccessfulAction = (payload: any) => ({
  type: LOCATION_VALIDATION_CONFIG_FETCHED,
  payload,
});

const locationValidationFetchFailedAction = (payload: any) => ({
  type: FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG_FAILED,
  payload,
});

export const resetVerificationLocationValidationConfigErrorAction = () => ({
  type: FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG_FAILED,
  payload: null,
});

export const getVerificationLocationValidationConfigConfigAction =
  () => async (dispatch: Dispatch) => {
    dispatch(isFetchingVerificationLocationValidationConfig(true));
    try {
      let response = await getVerificationLocationValidationConfig();
      if (
        !response.mediaCaptureRadius ||
        !response.verificationLocationRadius
      ) {
        throw new Error("Something went wrong");
      }
      dispatch(locationValidationFetchSuccessfulAction(response));
    } catch (error: any) {
      dispatch(
        locationValidationFetchFailedAction(
          error?.responseMessage || "Unable to fetch validation information"
        )
      );
    }
  };

export const setVerificationAddressAction =
  (address: string) => (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VERIFICATION_LOCATION_ADDRESS,
      payload: address,
    });
  };

export const setVerificationTaskLocationCoordinatesAction =
  (coords: { longitude: number | null; latitude: number | null }) =>
  (dispatch: Dispatch) => {
    console.log({ coords });
    dispatch({
      type: UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES,
      payload: {
        longitude: coords.longitude,
        latitude: coords.latitude,
      },
    });
  };

export const setVerificationOfficerLocationCoordinatesAction =
  (coords: { longitude: number | null; latitude: number | null }) =>
  (dispatch: Dispatch) => {
    console.log({ coords });
    dispatch({
      type: UPDATE_VERIFICATION_LOCATION_COORDINATES,
      payload: {
        longitude: coords.longitude,
        latitude: coords.latitude,
      },
    });
  };
export const resetVerificationOfficerLocationCoordinatesAction =
  () => (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_VERIFICATION_LOCATION_COORDINATES,
      payload: {
        longitude: null,
        latitude: null,
      },
    });
  };

export const resetVerificationTaskLocationCoordinatesAction =
  () => (dispatch: Dispatch) => {
    dispatch({
      type: UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES,
      payload: {
        longitude: null,
        latitude: null,
      },
    });
  };
