import React, { ReactNode } from "react";
import Footer from "./Footer";
import TopNav from "./TopNav";
import { Layout, Menu } from "antd";

const { Header, Sider, Content } = Layout;

interface Props {
  children: ReactNode;
  
}

const LandingPageShell: React.FC<Props> = (props) => {
  // const dispatch = useDispatch();
  // const user = useSelector((store: ReduxStoreModel) => store.user);

  // useEffect(() => {
  // 	if (user.data && user.data.token) {
  // 		history.push("/user/profile");
  // 	}
  // }, [user, dispatch]);

  return (
    <Layout>
      <div className="landing-page full-width">
        <TopNav />

        <div className="content-shell full-width full-screen-height">
          {props.children}
        </div>
        <Footer />
      </div>
    </Layout>
  );
};

export default LandingPageShell;
