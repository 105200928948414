const Language = {
    LoginPage: {
		loginError: "Unable to log user in, please try again later",
    },
	LoginPersistError: {
		errorMessage: "User must be logged in to perform this operation"
	},
	NetworkErrorMessage: {
		errorMessage: "Network Error",
	},
	PageLoaderComponent: { loadingText: "loading" },
	SuspenseFallback: {
		fallbackText: "loading",
	},
	SwitchTableSize: {
		switchOptionText: "records per page",
		pageSizeText: "Select Page Size",
	},
};

export default Language;
