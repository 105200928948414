import DashboardIcon from "common/components/CustomIcons/Sidebar/DashboardIcon";
import WalletIcon from "common/components/CustomIcons/Sidebar/WalletIcon";
import VerificationTasksIcon from "common/components/CustomIcons/Sidebar/TasksIcon";
import React from "react";
import { useLocation } from "react-router";
import SidebarItem from "./SidebarItem";
import history from "common/config/history";

const menuData = [
  { iconComponent: <DashboardIcon />, text: "Dashboard", url: "/dashboard" },
  { iconComponent: <WalletIcon />, text: "Wallet", url: "/wallet" },
  {
    iconComponent: <VerificationTasksIcon />,
    text: "Verification Tasks",
    url: "/tasks",
  },
];

interface ISidebarItemListProps {
  onItemClick(): void;
}

const SidebarItemList = ({ onItemClick }: ISidebarItemListProps) => {
  const location = useLocation();
  const isItemActive = (url: string) => location.pathname.includes(url);

  const handleNav = (url: string) => {
    history.push(url);
    onItemClick();
  };
  return (
    <div className="py-4 space-y-4">
      {menuData.map((item, index) => (
        <SidebarItem
          key={index}
          {...item}
          onClick={() => handleNav(item.url)}
          isItemActive={isItemActive(item.url)}
        >
          {/* Clone icon component and add dynamic fill */}
          {React.cloneElement(item.iconComponent, {
            fill: isItemActive(item.url) ? "#0361f0" : null,
          })}
        </SidebarItem>
      ))}
    </div>
  );
};

export default SidebarItemList;
