import { lazy } from "react";
import { RouteConfig, ROUTES } from "./types";

/**  function to lazy-load routes */
const loadModules = (link: string) =>
  lazy(() => import(`../../../modules/${link}`));

export const routes: RouteConfig[] = [
  {
    path: ROUTES.LOGIN,
    Component: loadModules("auth/pages/login"),
    access: "guest-only",
    exact: true,
  },
  {
    path: ROUTES.LOGIN_WITH_MONIEPOINT,
    Component: loadModules("auth/pages/login_with_moniepoint"),
    access: "guest-only",
    exact: true,
  },
  {
    path: ROUTES.PASSWORD_RECOVERY,
    Component: loadModules("auth/pages/password_recovery"),
    access: "guest-only",
    exact: true,
  },
  {
    path: ROUTES.DASHBOARD,
    Component: loadModules("dashboard"),
    access: "customer-only",
    exact: true,
  },
  {
    path: ROUTES.VERIFICATION_TASKS,
    Component: loadModules("verificationTasks"),
    access: "customer-only",
    exact: true,
  },
  {
    path: ROUTES.VERIFICATION_TASKS_PENDING,
    Component: loadModules("verificationTasks/pendingTasks"),
    access: "customer-only",
    exact: true,
  },
  {
    path: ROUTES.COMPLETED_VERIFICATION_TASKS_DETAILS_VIEW,
    Component: loadModules(
      "verificationTasks/historyTasks/pages/VerificationTaskDetails"
    ),
    access: "customer-only",
    exact: true,
  },
  {
    path: ROUTES.LOGIN_GSUITE,
    Component: loadModules("auth/pages/login/gSuiteLogin"),
    access: "customer-only",
    exact: true,
  },
  {
    path: ROUTES.WALLET,
    Component: loadModules("wallet"),
    access: "customer-only",
    exact: true,
  },
];
