import { Button } from "antd";
import Modal from "antd/lib/modal/Modal";
import * as React from "react";

export interface IActionConfirmationModalProps {
  isModalVisible: boolean;
  setIsModalVisible: (isModalVisible: boolean) => void;
  onConfirmation: Function;
  modalTitle: string;
  modalConfirmationMessage: string;
  cancelButtonText: string;
  okButtonText: string;
  okButtonColor: string;
  loading?: boolean;
}

export default function ActionConfirmationModal({
  isModalVisible,
  setIsModalVisible,
  cancelButtonText,
  modalConfirmationMessage,
  modalTitle,
  okButtonText,
  onConfirmation,
  okButtonColor,
  loading = false,
}: IActionConfirmationModalProps) {
  return (
    <Modal
      centered
      open={isModalVisible}
      footer={null}
      width={400}
      className="rounded-md bg-white"
      onCancel={() => setIsModalVisible(false)}
    >
      <div className="py-5">
        <h3 className="text-base font-semibold	">{modalTitle}</h3>
        <p className="font-light pt-2 pb-3 text-coral-black">
          {modalConfirmationMessage}
        </p>
        <div className="flex flex-row justify-end">
          <Button
            size="small"
            type="primary"
            className="mr-3 border-none bg-white h-10 text-white text-sm"
            onClick={() => onConfirmation()}
            loading={loading}
          >
            {okButtonText}
          </Button>
          <Button
            size="small"
            onClick={() => setIsModalVisible(false)}
            className="h-10 text-sm bg-cultured-white  text-coral-black"
          >
            {cancelButtonText}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
