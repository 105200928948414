export const ENDPOINTS = {
  auth: {
    cosmosLogin: {
      url: "https://moniesend-cosmos.development.teamapt.com",
      clientId: "field-verification-core-service",
      authDomain: "FIELD_VERIFICATION",
      method: "GET",
    },
    gSuiteLogin: {
      url: "/login",
      method: "POST",
    },
    login: {
      url: "/local-user-login",
      method: "POST",
    },
    loginWithMoniepointToken: {
      url: "/login-moniepoint",
      method: "POST",
    },
    forgotPassword: {
      url: "/users/forgot-password",
      method: "POST",
    },
  },
  verificationTasks: {
    uploadFile: {
      url: "/files/upload",
      method: "POST",
    },
    pendingTaskDetails: {
      url: "/verifications/pending/{id}",
      method: "GET",
    },
    pending: {
      url: "/verifications/pending",
      method: "POST",
    },
    cancelled: {
      url: "/verifications/cancelled",
      method: "POST",
    },
    history: {
      url: "/verifications/completed",
      method: "POST",
    },
    historyDetails: {
      url: "/verifications/completed/{id}",
      method: "GET",
    },
    businessPhysicalStructures: {
      url: "/config/business-physical-structures",
      method: "GET",
    },
    submitVerifications: {
      url: "/verification/submit-result",
      method: "POST",
    },
    initiateVerificationLivenessCheck: {
      url: "/liveness-check/initiation/verification",
      method: "POST",
    },
    initiateFvoLivenessCheck: {
      url: "/liveness-check/initiation/fvo",
      method: "POST",
    },
    liveNessCheckVerificationStatus: {
      url: "/liveness-check/status",
      method: "GET",
    },
    taskSummary: {
      url: "/verifications/summary",
      method: "GET",
    },
    config: {
      url: "/config/verification-location-validation",
      method: "GET",
    },
    businessCategories: {
      url: "/business-categories",
      method: "GET",
    },
    rejectTask: {
      url: "/verifications/reject",
      method: "POST",
    },
    verificationsDetailsByRequestReference: {
      url: "/verifications/{clientRequestReference}",
      method: "GET",
    },
  },
  wallet: {
    transactions: {
      url: "/fvo/wallet/transaction-history",
      method: "GET",
    },
    balance: {
      url: "/fvo/wallet/balance",
      method: "GET",
    },
    accountDetails: {
      url: "/fvo/wallet/cashout-account",
      method: "GET",
    },
    cashout: {
      url: "/fvo/wallet/cashout",
      method: "POST",
    },
  },
  dashboard: {
    taskSummary: {
      url: "/verifications/dashboard-summary",
      method: "GET",
    },
  },
};
