import React from "react";

export const Loader = () => {
  return (
    <>
      <div className="animate-pulse bg-grey-200 w-full rounded-sm height-45"></div>
      <div className="animate-pulse bg-grey-200 w-full rounded-sm height-45"></div>
    </>
  );
};

export const DescriptionRow = ({
  isLoading,
  children,
}: {
  isLoading?: boolean;
  children: JSX.Element | JSX.Element[];
}) => {
  return (
    <div
      className={`flex w-full space-x-5 space-y-0 lg:space-y-0 items-center sm:justify-start border-b border-b-primary-blue-10 py-3 ${
        isLoading ? `lg:space-x-7` : `lg:space-x-14`
      }`}
    >
      {isLoading ? <Loader /> : children}
    </div>
  );
};
