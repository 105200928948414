import React from "react";

const WalletIcon = (props: {
  height?: number;
  width?: number;
  fill?: string;
}) => {
  return (
    <svg
      width={props?.width || 24}
      height={props?.height || 24}
      viewBox={`0 0 ${props?.width || 24} ${props?.height || 24}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.47633 4.87561H19.4763C19.6515 4.87552 19.8266 4.88664 20.0004 4.90889C19.9415 4.49534 19.7994 4.09801 19.5828 3.74085C19.3662 3.38369 19.0795 3.0741 18.74 2.83073C18.4005 2.58737 18.0152 2.41528 17.6074 2.32484C17.1996 2.23441 16.7777 2.22749 16.3671 2.30451L4.03102 4.41061H4.01695C3.24261 4.55868 2.55401 4.99679 2.0918 5.63545C2.78817 5.14013 3.62177 4.8745 4.47633 4.87561Z"
        fill={props?.fill || "#9BA6B9"}
      />
      <path
        d="M19.4766 6H4.47656C3.68118 6.00087 2.91862 6.31722 2.3562 6.87964C1.79378 7.44206 1.47743 8.20462 1.47656 9V18C1.47743 18.7954 1.79378 19.5579 2.3562 20.1204C2.91862 20.6828 3.68118 20.9991 4.47656 21H19.4766C20.2719 20.9991 21.0345 20.6828 21.5969 20.1204C22.1593 19.5579 22.4757 18.7954 22.4766 18V9C22.4757 8.20462 22.1593 7.44206 21.5969 6.87964C21.0345 6.31722 20.2719 6.00087 19.4766 6ZM17.25 15C16.9533 15 16.6633 14.912 16.4166 14.7472C16.17 14.5824 15.9777 14.3481 15.8642 14.074C15.7506 13.7999 15.7209 13.4983 15.7788 13.2074C15.8367 12.9164 15.9796 12.6491 16.1893 12.4393C16.3991 12.2296 16.6664 12.0867 16.9574 12.0288C17.2483 11.9709 17.5499 12.0006 17.824 12.1142C18.0981 12.2277 18.3324 12.42 18.4972 12.6666C18.662 12.9133 18.75 13.2033 18.75 13.5C18.75 13.8978 18.592 14.2794 18.3107 14.5607C18.0294 14.842 17.6478 15 17.25 15Z"
        fill={props?.fill || "#9BA6B9"}
      />
    </svg>
  );
};

export default WalletIcon;
