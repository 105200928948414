import { API_URL, USE_MOCK } from "common/config/constants";
import { v4 as uuid4 } from "uuid";

interface ICanvasToFileProps {
  canvas: HTMLCanvasElement;
  fileName?: string;
  fileType?: string;
  IMEIExtension?: string;
}
export const getFileFromCanvas = ({
  canvas,
  fileName,
  fileType,
}: ICanvasToFileProps) => {
  return new Promise<File>((resolve) => {
    canvas.toBlob(async (blob) => {
      try {
        const buf = await new Response(blob).arrayBuffer();
        const file = new File([buf], `${fileName || uuid4()}.png`, {
          type: "image/png",
        });
        resolve(file);
      } catch (error) {
        throw new Error("Can't convert canvas to file");
      }
    }, "image/png");
  });
};

export const getBase64FromFile = (
  file: File
): Promise<string | ArrayBuffer | null> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const formatBytes = (bytes: number, decimals = 2) => {
  if (!+bytes) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const assetFromFileService = (reference: string) => {
  if (!reference) return "";
  const ref = USE_MOCK ? "2b2aab50-373a-45d1-8c07-32b9cbfb6e3c" : reference;
  return `${API_URL}/files/download-byte/${ref}`;
};
