import React from 'react';
import { Loader } from "../Loader";

interface IProps {
  text: string;
  hasCount?: boolean;
  count?: number;
  isLoading: boolean;
}

export const TabTitle = ({ text, hasCount = false, count = 0, isLoading }: IProps) => {
  return (
    <div className='flex items-center justify-between space-x-4'>
      <p className='font-medium text-sm text-color-inherit'>
        {text}
      </p>
      {hasCount && (
        isLoading ? <Loader /> : <p className='tab-count-tag h-5 pl-1 pr-1 flex items-center justify-center rounded text-white text-xs'>
          {count}
        </p>
      )}
    </div>
  );
};
