import { Input } from "antd";
import React, { CSSProperties, ReactElement } from "react";
interface ICustomInputFieldProps {
	placeholder?: string;
	id?: string;
	label?: string;
	type?: string;
	size?: "small" | "middle" | "large";
	suffix?: string | ReactElement;
	prefix?: string | ReactElement;
	min?: number;
	max?: number;
	customInputContainerStyles?: CSSProperties;
	customInputFieldStyles?: CSSProperties;
	InputComponent?: ReactElement;
}

export const CustomInputField = React.forwardRef(
	(
		{
			id = "",
			placeholder = "",
			label = "",
			type = "text",
			size = "middle",
			suffix,
			prefix,
			min,
			max,
			InputComponent,
			customInputContainerStyles,
			customInputFieldStyles,
		}: ICustomInputFieldProps,
		ref: any
	) => {
		return (
			<div className="custom-input" style={customInputContainerStyles}>
				{InputComponent ? (
					InputComponent
				) : (
					<Input
						min={min}
						max={max}
						size={size}
						id={id}
						ref={ref}
						type={type}
						placeholder={placeholder}
						prefix={prefix}
						suffix={suffix}
						style={customInputFieldStyles}
					/>
				)}
				{label && <label>{label}</label>}
			</div>
		);
	}
);
