import { formatBytes } from "common/utils/helpers/fileData";
import React, { FC } from "react";
import MediaThumbnail from "../MediaThumbnail";
import deleteIcon from "../../../../static/images/delete.svg";
import { Loader } from "common/components/Loader";
import { useFileServiceDownload } from "common/utils/hooks/useFileServiceDownload";

interface IMediaDisplayProps {
  mediaData: {
    src: string;
    reference?: string;
    size: number;
    isVideo?: boolean;
  };
  handleDeleteImage: () => void;
  isLoading?: boolean;
  showActions?: boolean;
  showBorder?: boolean;
  fileName: string;
}

const MediaDisplay: FC<IMediaDisplayProps> = ({
  mediaData,
  handleDeleteImage,
  isLoading = false,
  showActions = true,
  showBorder = true,
  fileName,
}) => {
  const { fileServiceDownloadData, isFetchingImageFromFileService } =
    useFileServiceDownload({
      fileReference: mediaData.reference || "",
    });

  const { contentType, base64String } = fileServiceDownloadData;

  return (
    <>
      <div
        className={`bg-secondary-faded-icon rounded-2xl w-full py-4 px-6 mb-4 ${
          showBorder ? " border border-primary-blue-10" : ""
        }`}
      >
        <div className="flex items-center justify-between">
          <div className="flex flex-row left-items align-items-center ">
            <MediaThumbnail
              isLoading={isLoading || isFetchingImageFromFileService}
              mediaData={{
                ...mediaData,
                src:
                  `data:${contentType};base64,${base64String}` || mediaData.src,
              }}
            />
            <div className="">
              <p>{fileName}</p>
              <p>{formatBytes(mediaData.size || 0)}</p>
            </div>
          </div>
          {showActions ? (
            <div className="right-items">
              {isLoading || isFetchingImageFromFileService ? (
                <Loader />
              ) : (
                <button onClick={handleDeleteImage} className="w-8 h-8">
                  <img
                    src={deleteIcon}
                    alt="delete"
                    className="w-full h-full object-contain"
                  />
                </button>
              )}
            </div>
          ) : null}
        </div>
      </div>
    </>
  );
};

export default MediaDisplay;
