import React, { useState } from "react";
import MediaPreviewModal from "../MediaPreviewModal";
import ExpandImageIcon from "../../CustomIcons/ExpandImageIcon";
import ExpandVideoIcon from "../../CustomIcons/ExpandVideoIcon";
import { Loader } from "common/components/Loader";

interface IMediaThumbnailProps {
  mediaData: {
    size: number;
    src: string;
    isVideo?: boolean;
  };
  isLoading: boolean;
}

const MediaThumbnail = ({ mediaData, isLoading }: IMediaThumbnailProps) => {
  const [isPreview, setIsPreview] = useState(false);
  const handleViewImage = () => {
    if (isLoading) return;
    setIsPreview(true);
  };

  return (
    <>
      <div className={"flex items-center justify-start space-x-6"}>
        <button className="pr-6" onClick={handleViewImage}>
          <div className="relative">
            <div
              className="w-14 h-14 border-4 border-white rounded-xl flex flex-col items-center justify-center media-thumbnail-container"
            >
              {isLoading ? (
                <Loader />
              ) : mediaData.isVideo ? (
                <video className="w-full h-full rounded-xl object-cover">
                  <source src={mediaData.src || ""} type="video/mp4" />
                  <source src={mediaData.src || ""} type="video/ogg" />
                  Your browser does not support HTML video.
                </video>
              ) : (
                <img
                  src={mediaData.src}
                  alt="upload"
                  className="w-full h-full rounded-lg object-cover"
                />
              )}
            </div>
            <div className="w-8 h-8 absolute bottom-0 -right-4">
              {isLoading ? null : mediaData.isVideo ? (
                <ExpandVideoIcon />
              ) : (
                <ExpandImageIcon />
              )}

              {/*   <img
                src={mediaData.isVideo ? expandVideoIcon : expandImageIcon}
                alt="expand"
                className="w-full h-full object-cover"
              />*/}
            </div>
          </div>
        </button>
      </div>
      {isPreview ? (
        <MediaPreviewModal
          isVideo={mediaData.isVideo}
          isOpen={isPreview}
          onClose={setIsPreview}
          previewMediaSrc={mediaData.src}
        />
      ) : null}
    </>
  );
};

export default MediaThumbnail;
