import React from "react";
import SidebarTop from "./SidebarTop";

import { useSelector } from "react-redux";
import { ReduxStoreModel } from "common/types";

import { animated, useSpring } from "react-spring";

import SidebarItemList from "./SidebarItemList";
import MoniepointLogo from "static/images/TopNav/logo.svg";
import burgerIcon from "static/images/TopNav/burger.svg";

interface ISidebarProps {
  onClose(): void;
  isOpen: boolean;
}
const Sidebar = ({ isOpen, onClose }: ISidebarProps) => {
  const user = useSelector((store: ReduxStoreModel) => store.user);

  const styleProps = useSpring({
    left: isOpen ? 0 : -256,
    position: "absolute",
    top: 0,
    backgroundColor: "#806290",
    height: "100vh",
    width: "256px",
  });

  return (
    <>
      <animated.div style={styleProps} className="z-50">
        <div className="flex items-center h-full justify-start">
          <div
            className="w-full bg-white h-full sidebar-container"
          >
            <div
              className={`flex items-center justify-between p-4 top-nav w-64 height-72`}
            >
              <div>
                <img src={MoniepointLogo} alt="logo" className="w-32 h-auto" />
              </div>
              <button
                onClick={onClose}
                className="border-none bg-transparent outline-none"
              >
                <img src={burgerIcon} alt="menu" className="w-6 h-6" />
              </button>
            </div>
            <SidebarTop
              name={`${user.data?.firstName || ""} ${
                user.data?.lastName || ""
              }`}
              email={user.data?.username || ""}
            />
            <SidebarItemList onItemClick={onClose} />
          </div>
        </div>
      </animated.div>
      {isOpen ? (
        <div
          onClick={onClose}
          className="absolute z-40 flex-grow w-screen h-screen backdrop-blur"
        ></div>
      ) : null}
    </>
  );
};

export default Sidebar;
