import React, { useState } from "react";
import cameraIcon from "../../../../static/images/camera.svg";
import { IMediaCapturedForPreviewAndPost } from "common/types/components";
import Camera from "common/components/Camera";
import EmptyMedia from "common/components/CustomIcons/EmptyMedia";
import MediaDisplay from "../MediaDisplay";
import VideoRecorder from "common/components/VideoRecorder";

interface IMediaUploadProps {
  onCapture: (data: IMediaCapturedForPreviewAndPost) => void;
  isVideo?: boolean;
  captureFacingMode?: "user" | "environment";
  fileName: string;
}

enum UPLOAD_STATES {
  DEFAULT = "default",
  UPLOADING = "uploading",
  ERROR = "error",
}

export const MediaUpload = ({
  onCapture,
  isVideo = false,
  captureFacingMode = "environment",
  fileName,
}: IMediaUploadProps) => {
  const [uploadState, setUploadState] = useState<UPLOAD_STATES>(
    UPLOAD_STATES.DEFAULT
  );
  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [mediaData, setMediaData] = useState<{
    size: number;
    src: string;
    isVideo?: boolean;
    reference?: string | undefined;
    fileName: string;
  }>({
    fileName,
    size: 0,
    src: "",
    isVideo: isVideo,
  });

  const resetMediaData = () => {
    setMediaData({ size: 0, src: "", isVideo, fileName: "", reference: "" });
  };

  const openCamera = () => {
    setIsCameraOpen(true);
  };

  const handleCapture = (data: IMediaCapturedForPreviewAndPost) => {
    console.log({ onCapture: data });
    setIsCameraOpen(false);
    setMediaData({
      fileName: data.name,
      reference: data.reference,
      size: data.size,
      src: data.src,
    });
    onCapture({
      reference: data?.reference,
      latitude: data?.latitude || 0,
      longitude: data?.longitude || 0,
      name: data.name,
      size: data.size,
      src: data.src,
      extension: data.extension,
    });
    setUploadState(UPLOAD_STATES.DEFAULT);
    resetMediaData();
  };

  return (
    <>
      {uploadState === UPLOAD_STATES.UPLOADING ? (
        <MediaDisplay
          isLoading
          handleDeleteImage={() => null}
          mediaData={mediaData}
          fileName={fileName}
        />
      ) : (
        <div className="border border-primary-blue-10 bg-secondary-faded-icon rounded-2xl w-full py-4 px-6 mb-4">
          <div className="flex items-center justify-between">
            <div>
              <EmptyMedia />
            </div>
            <div className="w-40">
              <button
                className="bg-primary-blue-10 w-full p-4 rounded-lg"
                onClick={openCamera}
              >
                <div className="flex itmes-center justify-center space-x-1">
                  <div className="w-6 h-6">
                    <img
                      src={cameraIcon}
                      alt="camera"
                      className="w-full h-full"
                    />
                  </div>
                  <p className="text-sm font-medium text-primary-blue">
                    {isVideo ? "Record Video" : "Take Photo"}
                  </p>
                </div>
              </button>
            </div>
          </div>
        </div>
      )}

      {isCameraOpen ? (
        <>
          {isVideo ? (
            <VideoRecorder
              isVideoRecorderOpen={isCameraOpen}
              setIsCameraOpen={setIsCameraOpen}
              onCapture={handleCapture}
            />
          ) : (
            <Camera
              facingMode={captureFacingMode}
              isCameraOpen={isCameraOpen}
              setIsCameraOpen={setIsCameraOpen}
              onCapture={handleCapture}
            />
          )}
        </>
      ) : null}
    </>
  );
};
