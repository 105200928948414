import { CustomButton } from "common/components/CustomButton";
import { IMediaFileSrc } from "common/types/components";
import React from "react";

interface IMediaPreviewAndSubmit {
  isVideo?: boolean;
  mediaSrc: string;
  isSubmitting: boolean;
  onSubmit: (data: IMediaFileSrc) => void;
  onRetry: () => void;
}
const MediaPreviewAndSubmit = ({
  isVideo,
  mediaSrc = "",
  isSubmitting,
  onSubmit,
  onRetry,
}: IMediaPreviewAndSubmit) => {
  return (
    <div className="mx-auto bg-white rounded-2xl">
      {isVideo ? (
        <video controls className="w-full h-full rounded-t-2xl" autoPlay>
          <source src={mediaSrc} type="video/mp4" />
          <source src={mediaSrc} type="video/ogg" />
          Your browser does not support HTML video.
        </video>
      ) : (
        <img
          src={mediaSrc}
          alt="preview"
          className="w-full h-full rounded-t-2xl"
        />
      )}
      <div className="flex items-center justify-center py-4">
        <div className="flex items-center justify-center space-x-4">
          <CustomButton
            antBtnType="link"
            actionText="Re-take"
            action={onRetry}
            disabled={isSubmitting}
          />
          <CustomButton
            actionText="Submit"
            action={onSubmit}
            loading={isSubmitting}
          />
        </div>
      </div>
    </div>
  );
};

export default MediaPreviewAndSubmit;
