import { API_VERIFICATION_TASK_MAP } from "modules/verificationTasks/constants";
import { VerificationType } from "modules/verificationTasks/pendingTasks/types/tasks-model";

export const convertApiTaskTypeToInternalTaskType = (
  apiTaskType: VerificationType
) => {
  return API_VERIFICATION_TASK_MAP[apiTaskType] || "";
};

export const generatePostRequestBodyForCustomSearch = (
  initialRequestParams: any
) => {
  const searchRequestBody: any = {};
  const customVerificationTypeSearchData: any = {};
  const customSearchFields = ["firstName", "lastName", "businessName"];
  let searchCount = 0;

  for (const fieldName in initialRequestParams) {
    if (Object.prototype.hasOwnProperty.call(initialRequestParams, fieldName)) {
      let fieldValue = initialRequestParams[fieldName];

      // skip if field value is not valid and not a number
      if (!fieldValue && typeof fieldValue !== "number") {
        continue;
      }

      // remove extra spaces before and after if value is a string
      if (typeof fieldValue === "string") {
        fieldValue = fieldValue.trim();
      }

      // add to custom search body or main request body
      if (customSearchFields.includes(fieldName)) {
        customVerificationTypeSearchData[fieldName] = fieldValue;
        searchCount += 1;
      } else {
        searchRequestBody[fieldName] = fieldValue;
      }
    }
  }

  // include custom search JSON if custom search params are added
  if (searchCount > 0) {
    searchRequestBody["customVerificationTypeSearchData"] = JSON.stringify(
      customVerificationTypeSearchData
    );
  }
  return searchRequestBody;
};
