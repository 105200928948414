import { notification } from "antd";
import { CustomButton } from "common/components/CustomButton";
import { IMediaFileSrc } from "common/types/components";
import useWindowDimensions from "common/utils/helpers/DOM";
import { getFileFromCanvas } from "common/utils/helpers/fileData";
import React, { useRef, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import Webcam from "react-webcam";

interface IWebcamWrapper {
  onCaptureStart: (x: boolean) => void;
  onCaptureEnd: (data: IMediaFileSrc) => void;
  onError: () => void;
  facingMode: "environment" | "user";
}
const WebcamWrapper = ({
  onCaptureEnd,
  onCaptureStart,
  onError,
  facingMode,
}: IWebcamWrapper) => {
  const webcamRef = useRef<Webcam>(null);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const [isCaptureLoading, setIsCaptureLoading] = useState(!!webcamRef.current);
  const [hasStartedCamera, setHasStartedCamera] = useState(false);

  const videoConstraints: MediaTrackConstraints = {
    width: windowWidth < 800 ? windowWidth : 768,
    height: windowHeight,
  };
  const handleCameraAccessDenied = (err: string | DOMException) => {
    if (err.toString().includes("NotAllowedError")) {
      notification.error({
        message: "Unable to use camera, allow access to camera first",
      });
      onError();
    }
  };

  const handleCameraStared = () => {
    setHasStartedCamera(true);
  };

  const handleCapture = async () => {
    onCaptureStart(true);
    setIsCaptureLoading(true);
    if (webcamRef.current) {
      try {
        const imageSrc = webcamRef.current.getScreenshot() || "";
        const canvas = webcamRef.current.getCanvas() as HTMLCanvasElement;
        const imageFile = await getFileFromCanvas({ canvas });

        setIsCaptureLoading(false);
        onCaptureEnd({ mediaFile: imageFile, mediaSrc: imageSrc });
      } catch (error) {
        notification.error({
          message: `Unable to process image`,
        });
        setIsCaptureLoading(false);
        onError();
      }
    } else {
      notification.error({
        message: `Unable to use camera`,
      });
      setIsCaptureLoading(false);
      onError();
    }
  };
  return (
    <>
      <Webcam
        screenshotFormat="image/png"
        videoConstraints={{ ...videoConstraints, facingMode }}
        ref={webcamRef}
        className="rounded-2xl"
        onUserMediaError={handleCameraAccessDenied}
        onUserMedia={handleCameraStared}
      />
      {hasStartedCamera ? null : (
        <>
          <div className="camera__bg"></div>
          <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center py-2">
            <div className="animate-spin">
              <CgSpinner size={24} fill="#0361F0" />
            </div>
          </div>
        </>
      )}
      {!hasStartedCamera ? null : (
        <div className="absolute left-0 right-0 bottom-0 flex items-center justify-center py-2">
          <CustomButton
            actionText="Capture"
            action={handleCapture}
            loading={isCaptureLoading}
          />
        </div>
      )}
    </>
  );
};

export default WebcamWrapper;
