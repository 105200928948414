import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import React from "react";

const antIcon = <LoadingOutlined spin />;

export const Loader = ({ center }: { center?: boolean }) => {
  return (
    <div className={center ? "flex justify-center items-center h-32" : ""}>
      <Spin indicator={antIcon} />
    </div>
  );
};
