import { ReduxActionModel } from "common/types";
import { SIDEBAR_CLOSED, SIDEBAR_OPENED } from "../actions/types";

let initialState = {
  isOpen: false,
};

export default (state = initialState, action: ReduxActionModel) => {
  switch (action.type) {
    case SIDEBAR_OPENED:
      state = { ...state, isOpen: true };
      break;
    case SIDEBAR_CLOSED:
      state = { ...state, isOpen: false };
      break;

    default:
      break;
  }
  return state;
};
