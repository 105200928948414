import React, { useCallback, useEffect, useRef, useState } from "react";
import Webcam from "react-webcam";
import { CustomButton } from "../../CustomButton";
import { v4 as uuid4 } from "uuid";
import { notification, Statistic } from "antd";
import { CgSpinner } from "react-icons/cg";
import { IMediaFileSrc } from "common/types/components";
import useWindowDimensions from "common/utils/helpers/DOM";

interface IVideoRecorderWrapperProps {
  onRecordStart: (x: boolean) => void;
  onRecordEnd: (data: IMediaFileSrc) => void;
  onError: () => void;
}
const { Countdown } = Statistic;
export const VideoRecorderWrapper = ({
  onRecordStart,
  onRecordEnd,
  onError,
}: IVideoRecorderWrapperProps) => {
  const videoDuration = Date.now() + 1000 * 30; // 30secs count down
  const webcamRef = useRef<Webcam>(null);
  const mediaRecorderRef = useRef<Webcam>(null);
  const [capturing, setCapturing] = useState(false);
  const [isFetchingLocation, setIsFetchingLocation] = useState(false);
  const [hasStartedRecorder, setHasStartedRecorder] = useState(false);
  const { height: windowHeight, width: windowWidth } = useWindowDimensions();

  const [videoLimitElapsed, setVideoLimitElapsed] = useState(false);

  const videoConstraints: MediaTrackConstraints = {
    width: windowWidth < 800 ? windowWidth : 768,
    height: windowHeight,
    facingMode: "environment",
  };

  const handleRecorderStared = () => {
    setHasStartedRecorder(true);
  };

  const videoMaxLimitRef = useRef<any>(null);

  useEffect(() => {
    const runVerifications = async () => {
      if (!isFetchingLocation) {
        setIsFetchingLocation(true);
      }
    };
    runVerifications();
  }, []);

  const handleCapture = useCallback(() => {
    onRecordStart(true);
    let capturingStarted = true;
    setCapturing(capturingStarted);
    setTimeout(() => {
      setVideoLimitElapsed(true);
      if (capturingStarted) {
        onCloseCamera();
      }
    }, 30000);
    // @ts-ignore
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    if (mediaRecorderRef.current) {
      // @ts-ignore
      mediaRecorderRef.current.addEventListener(
        "dataavailable",
        handleDataAvailable
      );
      // @ts-ignore
      mediaRecorderRef.current.start();
    }
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = ({ data }: any) => {
    if (data.size > 0) {
      processCapturedMedia(data);
    }
  };

  const onCloseCamera = useCallback(() => {
    clearTimeout(videoMaxLimitRef.current);
    if (mediaRecorderRef.current) {
      // @ts-ignore
      mediaRecorderRef.current.stop();
    }
    setCapturing(false);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const processCapturedMedia = async (recordedChunks: Blob) => {
    if (recordedChunks) {
      let blob = recordedChunks;
      const buf = await new Response(blob).arrayBuffer();
      let file = new File([buf], `${uuid4()}.mp4`);
      let mediaBlobUrl = URL.createObjectURL(blob);
      if (!mediaBlobUrl) return;
      const reader = new FileReader();
      fetch(mediaBlobUrl)
        .then((res) => res.blob())
        .then((blob) => {
          reader.readAsDataURL(blob);
          reader.onloadend = () => {
            onRecordEnd({
              mediaFile: file,
              mediaSrc: reader.result?.toString() || "",
            });
            // closeCamera(false);
          };
        });
    }
  };

  const handleRecorderAccessDenied = (err: string | DOMException) => {
    if (err.toString().includes("NotAllowedError")) {
      notification.error({
        message: "Unable to use recorder, allow access to camera first",
      });
      onError();
    }
  };

  return (
    <>
      <section className="w-full relative rounded-2xl">
        {
          <>
            <Webcam
              audio={false}
              ref={webcamRef}
              videoConstraints={videoConstraints}
              className="rounded-2xl"
              onUserMedia={handleRecorderStared}
              onUserMediaError={handleRecorderAccessDenied}
            />
            {hasStartedRecorder ? null : (
              <>
                <div className="camera__bg"></div>
                <div className="absolute left-0 right-0 top-0 bottom-0 flex items-center justify-center py-2 mb-10">
                  <div className="animate-spin">
                    <CgSpinner size={24} fill="#0361F0" />
                  </div>
                </div>
              </>
            )}
            {capturing && videoLimitElapsed ? (
              <section className="absolute left-0 right-0 -bottom-20 flex items-center justify-center py-2">
                <div className="w-56">
                  <CustomButton
                    actionText="Stop Record"
                    action={onCloseCamera}
                    antBtnType="ghost"
                    className="w-full"
                  />
                </div>
              </section>
            ) : capturing && !videoLimitElapsed ? (
              <section className="absolute left-0 right-0 -bottom-20 flex items-center justify-center py-2">
                <div
                  className="w-56 bg-gray-600 flex justify-center items-center border-radius-10 webcam-countdown-container"
                >
                  <Countdown
                    className="text-white"
                    title=""
                    value={videoDuration}
                    format="mm:ss"
                  />
                </div>
              </section>
            ) : (
              <section className="absolute left-0 right-0 bottom-0 flex items-center justify-center py-2">
                <CustomButton
                  disabled={!hasStartedRecorder}
                  actionText="Record Video"
                  action={handleCapture}
                />
              </section>
            )}
          </>
        }
      </section>
    </>
  );
};
