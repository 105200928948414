import React, { FC, useEffect } from "react";
// @ts-ignore
import { Document, Page } from "react-pdf";
import { CustomButton } from "../../CustomButton";
import Modal from "../../Modal";

interface IMediaPreviewProps {
  isOpen: boolean;
  isVideo?: boolean;
  previewMediaSrc: string;
  onClose: (x: boolean) => void;
}

const MediaPreviewModal: FC<IMediaPreviewProps> = ({
  isVideo,
  isOpen,
  onClose,
  previewMediaSrc,
}) => {
  const isPdf = previewMediaSrc.startsWith("data:application/pdf");

  return (
    <Modal
      open={isOpen}
      hideClose
      hideFooter
      size={"lg"}
      contentWrapperClassName={`p-0 py-0 px-0 rounded-2xl`}
    >
      <section className="w-full relative rounded-2xl">
        {isVideo ? (
          <video controls className="w-full h-full rounded-2xl" autoPlay>
            <source src={previewMediaSrc || ""} type="video/mp4" />
            <source src={previewMediaSrc || ""} type="video/ogg" />
            Your browser does not support HTML video.
          </video>
        ) : isPdf ? (
          <div className="h-full">
            <Document
              file={previewMediaSrc}
              className="w-full h-full rounded-2xl"
            >
              <Page pageNumber={1} width={600} />
            </Document>
          </div>
        ) : (
          <img
            src={previewMediaSrc || ""}
            alt="preview"
            className="w-full h-full rounded-2xl"
          />
        )}
        <section className="absolute left-0 right-0 -bottom-20 flex items-center justify-center py-2">
          <div className="w-56">
            <CustomButton
              actionText="Close"
              action={() => {
                onClose(false);
              }}
              antBtnType="ghost"
              className="w-full"
            />
          </div>
        </section>
      </section>
    </Modal>
  );
};

export default MediaPreviewModal;
