import React, { useState } from "react";
import { postUploadFile } from "modules/verificationTasks/pendingTasks/service";
import {
  ILocationCoordinates,
  IMediaCapturedForPreviewAndPost,
  IMediaFileSrc,
} from "common/types/components";
import { message, notification } from "antd";
import { generateErrorMessage } from "common/services";

interface IuseUploadMedia {
  onUploadSuccess: (data: IMediaCapturedForPreviewAndPost) => void;
}
export const useUploadMedia = ({ onUploadSuccess }: IuseUploadMedia) => {
  const [isSubmittingMedia, setIsSubmittingMedia] = useState(false);

  const uploadCapturedMedia = ({
    data,
    latitude,
    longitude,
  }: ILocationCoordinates & { data: IMediaFileSrc }) => {
    postUploadFile(data.mediaFile)
      .then((res) => {
        onUploadSuccess({
          reference: res?.reference,
          latitude: latitude || 0,
          longitude: longitude || 0,
          name: data.mediaFile.name,
          size: data.mediaFile.size,
          src: "",
          extension: data.mediaFile.type,
        });
      })
      .catch((error) => {
        message.error(generateErrorMessage(error));
      })
      .finally(() => {
        setIsSubmittingMedia(false);
      });
  };

  const handleUpload = (
    data: IMediaFileSrc,
    geolocation: ILocationCoordinates
  ) => {
    console.log("useUploadMedia starts");
    setIsSubmittingMedia(true);
    uploadCapturedMedia({ ...geolocation, data });
  };
  return { isSubmittingMedia, handleUpload };
};

export default useUploadMedia;
