import { ReduxActionModel } from "common/types";
import {
  IS_FETCHING_VERIFICATION_TASKS_SUMMARY,
  VERIFICATION_TASKS_SUMMARY_FETCHED,
} from "../actions/types";

const initialState = {
  isLoading: true,
  data: {},
};

export default (state = initialState, action: ReduxActionModel) => {
  switch (action.type) {
    case IS_FETCHING_VERIFICATION_TASKS_SUMMARY:
      state = { ...state, isLoading: action.payload };
      break;
    case VERIFICATION_TASKS_SUMMARY_FETCHED:
      state = { ...state, isLoading: false, data: action.payload };
      break;
    default:
      break;
  }
  return state;
};
