import { Row } from "antd";
import React from "react";
import Language from "../../utils/language/en";
import { Loader } from "../Loader";

const { SuspenseFallback: PageDictionary } = Language;

export const SuspenseFallback = () => (
  <Row className="p-5 flex flex-col items-center">
    <Loader />
    {PageDictionary.fallbackText}
  </Row>
);
