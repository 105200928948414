import { Modal } from "antd";
import React, { ReactElement, Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { SuspenseFallback } from "../";
import { routes } from "./routes";
import { RouteConfig, RoutesProps } from "./types";

/** function to generate routes */
const generateRoutes = ({
  path,
  exact,
  Component,
}: RouteConfig): ReactElement => {
  Modal.destroyAll();
  return (
    <Route
      key={path}
      path={path}
      exact={exact || false}
      render={(props) => <Component {...props} />}
    />
  );
};

/** routes component */

const Routes: React.FunctionComponent<RoutesProps> = () => {
  return (
    <Suspense fallback={<SuspenseFallback />}>
      <Switch>
        {routes.map((route) => generateRoutes(route))}

        {/* render login page if none of the routes above is matched */}
        <Route path="/" render={() => <Redirect to="/login" />} />
      </Switch>
    </Suspense>
  );
};

export default Routes;
