export const VERIFICATION_TASKS_HISTORY_FETCHED =
  "VERIFICATION_TASKS_HISTORY_FETCHED";

export const LOCATION_VALIDATION_CONFIG_FETCHED =
  "LOCATION_VALIDATION_CONFIG_FETCHED";
export const FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG_FAILED =
  "FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG_FAILED";

export const FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG =
  "FETCHING_VERIFICATION_LOCATION_VALIDATION_CONFIG";

export const UPDATE_VERIFICATION_LOCATION_ADDRESS =
  "UPDATE_VERIFICATION_LOCATION_ADDRESS";

export const UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES =
  "UPDATE_TASK_VERIFICATION_LOCATION_COORDINATES";
export const UPDATE_VERIFICATION_LOCATION_COORDINATES =
  "UPDATE_VERIFICATION_LOCATION_COORDINATES";
