import { message } from "antd";
import { getFromFileService } from "common/services/request/fileService";
import { useEffect, useState } from "react";

interface IuseFileServiceDownloadParams {
  fileReference: string | undefined;
}
export const useFileServiceDownload = ({
  fileReference,
}: IuseFileServiceDownloadParams) => {
  const [isFetchingImageFromFileService, setIsFetchingImageFromFileService] =
    useState(!!fileReference);
  const [fileServiceDownloadData, setFileServiceDownloadData] = useState({
    contentType: "",
    base64String: "",
  });
  const fetchApi = () => {
    setIsFetchingImageFromFileService(true);
    getFromFileService(fileReference || "")
      .then((res) => {
        setFileServiceDownloadData({
          contentType: res.contentType,
          base64String: res.base64String,
        });
        setIsFetchingImageFromFileService(false);
      })
      .catch((e) => {
        message.error(e);
      });
  };

  useEffect(() => {
    if (!fileReference) return;
    fetchApi();
  }, [fileReference]);

  return {
    fetchApi,
    fileServiceDownloadData,
    isFetchingImageFromFileService,
  };
};
