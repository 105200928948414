export const businessVerificationTaskDetails = {
  businessCategory: "Kisok",
  verificationData: {
    businessNameIsCorrect: true,
    businessNameVerificationProof: [{ reference: "" }],
    businessLocationIsWithinBarracks: true,
    businessAddressIsCorrect: true,
    businessCanBeAccessedWithoutSecurityInterference: true,
    isEasyAccessToBusinessAddressPrevented: false,
    businessPhysicalStructure: "Kiosk",
    isBusinessCategoryCorrect: true,
  },
  verificationRequestMetadata:
    '{"businessName" : "jufe", "businessType" : "makeup", "businessPhoneNumber" : "098765432", "businessOwnerName" : "Jane", "businessCategory" : "finance"}',
};
export const guarantorVerificationDetails = {
  id: 5,
  verificationType: "GUARANTOR",
  requestDate: "2023-02-23T18:23:37",
  assignedDate: "2023-02-23T18:23:37",
  dueDate: "2023-02-25T18:23:37",
  completedDate: "2023-02-24T15:08:23",
  verificationResult: "PASSED",
  verificationAddress: {
    addressLineOne: "Address line1",
    addressLineTwo: "",
    city: "EKITI",
    lga: "NGA-EK-001",
    locationCoordinates: {
      longitude: 10.0880808,
      latitude: 2344.090908,
    },
  },
  verificationRequestMetadata:
    '{"firstName" : "John", "lastName" : "Doe", "gender" : "MALE", "phoneNumber" : "0123456789", "emailAddress" : "johndoe@yahoo.com","businessCategory" : "sales","subjectAddress" : "owner address 12,odoewu","subjectName" : "kufreg","businessAddress" : "business address 12,ododo","image" : "d6c56707-71c0-4cd1-a0cb-6e34ad212795"}',
  verificationSubjectImage: "d6c56707-71c0-4cd1-a0cb-6e34ad212795",
  businessCategory: "sales",
  verificationData: {
    isGuarantorLivingAtAddress: true,
    isBusinessAddressKnown: true,
    isBusinessCategoryKnown: true,
    knowsSubjectAddress: true,
    doesGuarantorKnowSubject: true,
    relationshipWithSubject: "father",
    relationshipWithSubjectDuration: "1",
    outsideResidenceImages: [
      {
        reference: "d73178ab-717d-4e42-995b-1344461edbbe",
        longitude: 20.1,
        latitude: 22.2,
      },
    ],
    insideResidenceImages: [
      {
        reference: "77fee7b2-88cb-4582-a984-12ea50111dd2",
        longitude: 20.1,
        latitude: 22.2,
      },
    ],
    guarantorLivenessCheck: "SUCCESS",
  },
};
export const businessOwnerVerificationDetails = {
  id: 10,
  verificationType: "BUSINESS_OWNER",
  requestDate: "2023-02-06T17:47:48",
  assignedDate: "2023-02-06T17:47:48",
  dueDate: "2023-02-08T17:47:48",
  completedDate: "2023-02-06T17:53:16",
  verificationResult: "PASSED",
  verificationAddress: {
    addressLineOne: "Wole Ariyo",
    addressLineTwo: "",
    city: "Lagos",
    lga: "NGA-EK-005",
    locationCoordinates: {
      longitude: 10.7,
      latitude: 11.2,
    },
  },
  verificationRequestMetadata:
    '{"firstName" : "temi", "middleName" : "temss", "lastName" : "kint", "gender" : "FEMALE", "emailAddress" : "johnyy@", "phoneNumber" : "0987654", "image" :  "d73178ab-717d-4e42-995b-1344461edbbe"}',
  verificationSubjectImage: "d73178ab-717d-4e42-995b-1344461edbbe",
  businessCategory: "",
  verificationData: {
    isBusinessOwnerLivingAtAddress: true,
    neighbourConfirmsBusinessOwnersAddress: true,
    outsideResidenceImages: [
      {
        reference: "d8084205-e0a5-43de-8f1b-3a44cd8331a4",
        longitude: 20.1,
        latitude: 22.2,
      },
    ],
    insideResidenceImages: [
      {
        reference: "d73178ab-717d-4e42-995b-1344461edbbe",
        longitude: 20.1,
        latitude: 22.2,
      },
    ],
    businessOwnerLivenessCheckStatus: "SUCCESS",
  },
};

export const completedIndividualAddressVerificationDetails = {
  id: 1328,
  verificationType: "INDIVIDUAL_ADDRESS",
  requestDate: "2024-05-22T16:18:09",
  assignedDate: "2024-05-22T16:18:09",
  dueDate: "2024-05-24T16:18:09",
  verificationAddress: {
    addressLineOne:
      "202, Wole oriyomi , Adek and co. close  in Address line1, pertrol chemical engineering road",
    addressLineTwo: "Sango bello close , unmuajs",
    city: "Lekki",
    lga: "Aba South",
    locationCoordinates: {
      longitude: 3.3488896,
      latitude: 6.5568768,
    },
  },
  subjectName: "Adams Johnson",
  completedDate: "2024-05-31T18:44:06",
  verificationResult: "PASSED",
  dateCancelled: null,
  businessCategory: "",
  verificationRequestMetadata:
    '{"firstName" : "Adams", "lastName" : "Johnson", "gender" : "FEMALE", "phoneNumber" : "0906789907548", "emailAddress" : "adamsJohnson@yahoo.com","image" : "1c22cc78-1f24-4d1b-afec-01d2bf02aeee","addressDocument" : "c873c318-4f36-43b2-9d17-cec2e669000b","landmarkName" : "Swis Bank"}',
  verificationData: {
    verificationLocation: {
      longitude: 3.3843462,
      latitude: 6.4971997,
    },
    picturesOfBuilding: [
      {
        reference: "2ae3bf83-4351-4715-a60c-e6f4a8a8f4c4",
        longitude: 3.3843462,
        latitude: 6.4971997,
      },
      {
        reference: "bc4d3c10-bdbc-4dcf-a47a-55e9fba7794d",
        longitude: 3.3843462,
        latitude: 6.4971997,
      },
    ],
    buildingType: "Bungalow",
    nearestLandmarkType: "Church",
    nearestLandmarkName: "Methodist Church Yaba",
    gateColor: "Blue",
    fenceColor: "Blue",
    houseColor: "Blue",
    onsiteIntervieweeName: "The Person",
    onsiteIntervieweeRelationshipToIndividual: "The Person",
    onsiteIntervieweeAddress: null,
    onsiteIntervieweePhoneNumber: null,
    fvoComment: "A successful verification, this person lives here",
    addressDescription:
      "The building is opposite the Methodist church and near KFC restaurant",
    isAddressDocumentValid: true,
    isAddressValid: true,
    buildingExists: true,
    isIndividualAtAddress: true,
  },
  clientVerificationReference: "MVR-I56524601",
  ignoreFvoVerificationLocationValidation: true,
  rejectable: true,
};

export const verificationTaskDetailsByClientReference = {
  id: 31,
  verificationType: "GUARANTOR",
  requestDate: "2023-06-03T20:37:34",
  assignedDate: "2023-06-03T20:37:34",
  dueDate: "2023-06-05T20:37:34",
  verificationAddress: {
    addressLineOne:
      "202, Wole oriyomi , Adek and co. close  in Address line1, pertrol chemical engineering road",
    addressLineTwo: "Sango bello close , unmuajs",
    city: "LAGOS",
    lga: "Ajeromi-Ifelodun",
    locationCoordinates: {
      longitude: 10.0880808,
      latitude: 2344.090908,
    },
  },
  subjectName: "Sherlock Homes",
  completedDate: "2023-06-03T20:54:48",
  verificationResult: "PASSED",
  dateCancelled: null,
  clientRequestReference: "demo-Guar-202306030836",
  businessCategory: "sales",
  isRejectable: false,
  ignoreFvoVerificationLocationValidation: false,
  verificationRequestMetadata:
    '{"firstName" : "Sherlock", "lastName" : "Homes", "gender" : "MALE", "phoneNumber" : "0123456789", "emailAddress" : "johndoe@yahoo.com","businessCategory" : "sales","subjectAddress" : "owner address 12,odoewu","subjectName" : "Bestie Ownner","businessAddress" : "business address 12,ododo","image" : "1c22cc78-1f24-4d1b-afec-01d2bf02aeee","verificationSubjectImage" : "1b338147-b035-496a-adab-22c6245f6213"}',
  status: "COMPLETED",
  clientVerificationReference: "demo-Guar-202306030836",
};
