import { IAddressVerificationRequestBody } from "../../individualAddressVerification/types";
export const businessCategories = [
  {
    id: 3,
    name: "Agriculture",
    description: "Agriculture",
    iconLink: null,
  },
  {
    id: 31,
    name: "Art and Creatives",
    description: "Art and Creatives",
    iconLink: null,
  },
  {
    id: 5,
    name: "Beauty and Personal Care",
    description: "Beauty and Personal Care",
    iconLink: null,
  },
  {
    id: 14,
    name: "Betting and Lottery",
    description: "Betting and Lottery",
    iconLink: null,
  },
  {
    id: 30,
    name: "Casual use",
    description: "Casual use",
    iconLink: null,
  },
  {
    id: 39,
    name: "Charities and Membership",
    description: "Charities and Membership",
    iconLink: null,
  },
  {
    id: 4,
    name: "Construction",
    description: "Construction",
    iconLink: null,
  },
  {
    id: 2,
    name: "Education",
    description: "Education",
    iconLink: null,
  },
  {
    id: 11,
    name: "Events and Entertainment",
    description: "Events and Entertainment",
    iconLink: null,
  },
  {
    id: 35,
    name: "Fashion",
    description: "Fashion",
    iconLink: null,
  },
  {
    id: 1,
    name: "Financial Services",
    description: "Financial Services",
    iconLink: null,
  },
  {
    id: 38,
    name: "Fitness",
    description: "Fitness",
    iconLink: null,
  },
  {
    id: 6,
    name: "Food and Drinks",
    description: "Food and Drinks",
    iconLink: null,
  },
  {
    id: 17,
    name: "Governmental Collections",
    description: "The business type for governments",
    iconLink: null,
  },
  {
    id: 16,
    name: "Health care",
    description: "Health related services",
    iconLink: null,
  },
  {
    id: 13,
    name: "Home and Repair",
    description: "Home and Repair",
    iconLink: null,
  },
  {
    id: 7,
    name: "IT/Electronics",
    description: "IT/Electronics",
    iconLink: null,
  },
  {
    id: 9,
    name: "Manufacturing",
    description: "Manufacturing",
    iconLink: null,
  },
  {
    id: 15,
    name: "Non Profit",
    description: "Non Profit",
    iconLink: null,
  },
  {
    id: 8,
    name: "Oil and Gas",
    description: "Oil and Gas",
    iconLink: null,
  },
  {
    id: 37,
    name: "Pet Care",
    description: "Pet Care",
    iconLink: null,
  },
  {
    id: 33,
    name: "Professional Services",
    description: "Professional Services",
    iconLink: null,
  },
  {
    id: 12,
    name: "Religious Institutions",
    description: "Religious Institutions",
    iconLink: null,
  },
  {
    id: 10,
    name: "Retail and General Trade",
    description: "Retail and General Trade",
    iconLink: null,
  },
  {
    id: 36,
    name: "Transportation",
    description: "Transportation",
    iconLink: null,
  },
  {
    id: 34,
    name: "Travel and Leisure",
    description: "Travel and Leisure",
    iconLink: null,
  },
];

/*Sub-categories for Agric*/
export const businessSubCategories = [
  {
    id: 7,
    name: "Agro chemicals & Fertilizers",
    code: "008",
    description: "Agro chemicals & Fertilizers ",
    businessCategoryName: "Agriculture",
    businessCategoryId: 3,
    default: false,
  },
  {
    id: 6,
    name: "Agro Processing",
    code: "007",
    description: "Agro Processing",
    businessCategoryName: "Agriculture",
    businessCategoryId: 3,
    default: false,
  },
  {
    id: 334,
    name: "Animal Feed Sales",
    code: "ANIMAL_FEED",
    description: "Animal Feed Sales",
    iconLink: "Animal",
    businessCategoryName: "Agriculture",
    businessCategoryId: 3,
    default: false,
  },
  {
    id: 344,
    name: "Cocoa Merchant",
    code: "Cocoa Merchant",
    description: "Cocoa Merchant",
    iconLink: "Cocoa Merchant",
    businessCategoryName: "Agriculture",
    businessCategoryId: 3,
    default: false,
  },
  {
    id: 276,
    name: "Crop & Livestock Farming",
    code: "Crop & Livestock Farming",
    description: "Crop & Livestock Farming",
    iconLink: "",
    businessCategoryName: "Agriculture",
    businessCategoryId: 3,
    default: false,
  },
  {
    id: 8,
    name: "Crop Farming",
    code: "009",
    description: "Crop Farming",
    businessCategoryName: "Agriculture",
    businessCategoryId: 3,
    default: false,
  },
  {
    id: 11,
    name: "Others",
    code: "012",
    description: "Others",
    businessCategoryName: "Agriculture",
    businessCategoryId: 3,
    default: false,
  },
  {
    id: 277,
    name: "Others",
    code: "O20",
    description: "Others",
    iconLink: "",
    businessCategoryName: "Agriculture",
    businessCategoryId: 3,
    default: false,
  },
  {
    id: 9,
    name: "Poultry/piggery/Animal Farming",
    code: "010",
    description: "Poultry/piggery/Animal Farming",
    businessCategoryName: "Agriculture",
    businessCategoryId: 3,
    default: false,
  },
  {
    id: 10,
    name: "Raw Foods",
    code: "011",
    description: "Raw Foods",
    businessCategoryName: "Agriculture",
    businessCategoryId: 3,
    default: false,
  },
];
