import React, { FC } from "react";
import { RiCloseLine } from "react-icons/ri";
import { Modal as ModalComponent } from "antd";

type TSIZE = "sm" | "md" | "lg" | "xl";

export interface IProps {
  open: boolean;
  title?: string;
  header?: any;
  description?: string;
  hideClose?: boolean;
  hideFooter?: boolean;
  closeOnOutsideClick?: boolean;
  contentWrapperClassName?: string;
  size?: TSIZE;
  children: JSX.Element | JSX.Element[];
  isBackgroundTransparent?: boolean;

  onClose?(): void;
}

const Modal: FC<IProps> = ({
  open,
  onClose,
  title,
  header,
  description,
  hideClose,
  hideFooter,
  closeOnOutsideClick,
  contentWrapperClassName,
  size = "lg",
  isBackgroundTransparent,
  children,
}) => {
  const SIZING = {
    sm: 384,
    md: 499,
    lg: 611,
    xl: 768,
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
  };

  const handleCloseOnOutsideClick = () => {
    if (onClose && closeOnOutsideClick) {
      onClose();
    }
  };

  return (
    <ModalComponent
      open={open}
      width={SIZING[size]}
      onCancel={handleCloseOnOutsideClick}
      footer={hideFooter ? null : hideFooter}
      closable={!hideClose}
      wrapClassName="backdrop-blur"
      //   className='p-0 w-fit h-fit border-none top-12 transition-all transform'
    >
      <div
        className={`p-6 ${
          isBackgroundTransparent ? " bg-transparent " : " bg-white "
        } ${contentWrapperClassName}`}
      >
        {header ? header : null}
        {hideClose === true ? null : (
          <div className="flex justify-between">
            <div>
              <h1 className="font-bold text-2xl">{title}</h1>
              <h4 className="">{description}</h4>
            </div>

            <RiCloseLine
              className="h-6 w-6 font-bold cursor-pointer"
              onClick={handleClose}
            />
          </div>
        )}
        {children}
      </div>
    </ModalComponent>
  );
};

export default Modal;
