import Icon from "@ant-design/icons";
import React from "react";

export default function ExpandImageIcon(props: {
  height?: number;
  width?: number;
  fill?: string;
}) {
  return (
    <Icon
      component={() => (
        <svg
          width={props.width || 32}
          height={props.height || 32}
          viewBox={`0 0 ${props.height || 32} ${props.width || 32}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="16" fill="#0361F0" />
          <g clipPath="url(#clip0_3511_2779)">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8.88361 8.30859H12.9907C13.3147 8.30859 13.5774 8.57265 13.5774 8.89839C13.5774 9.22412 13.3147 9.48818 12.9907 9.48818L10.3001 9.48818L14.579 13.7895C14.8081 14.0198 14.8081 14.3932 14.579 14.6236C14.3499 14.8539 13.9784 14.8539 13.7493 14.6236L9.47033 10.3223L9.47033 13.0269C9.47033 13.3527 9.20765 13.6167 8.88361 13.6167C8.55957 13.6167 8.29688 13.3527 8.29688 13.0269L8.29688 8.89839C8.29688 8.57265 8.55957 8.30859 8.88361 8.30859ZM8.8836 23.7964H12.9907C13.3147 23.7964 13.5774 23.5323 13.5774 23.2066C13.5774 22.8809 13.3147 22.6168 12.9907 22.6168H10.3001L14.579 18.3155C14.8081 18.0852 14.8081 17.7117 14.579 17.4814C14.3499 17.2511 13.9784 17.2511 13.7493 17.4814L9.47033 21.7827L9.47033 19.078C9.47033 18.7523 9.20764 18.4883 8.8836 18.4883C8.55956 18.4883 8.29688 18.7523 8.29688 19.078V23.2066C8.29688 23.5323 8.55956 23.7964 8.8836 23.7964ZM19.0572 23.7964H23.1642C23.4883 23.7964 23.751 23.5323 23.751 23.2066L23.751 19.078C23.751 18.7523 23.4883 18.4883 23.1642 18.4883C22.8402 18.4883 22.5775 18.7523 22.5775 19.078L22.5775 21.7827L18.2986 17.4814C18.0695 17.2511 17.698 17.2511 17.4688 17.4814C17.2397 17.7117 17.2397 18.0852 17.4688 18.3155L21.7478 22.6168H19.0572C18.7331 22.6168 18.4704 22.8809 18.4704 23.2066C18.4704 23.5323 18.7331 23.7964 19.0572 23.7964ZM23.1643 8.30859H19.0572C18.7331 8.30859 18.4704 8.57265 18.4704 8.89839C18.4704 9.22412 18.7331 9.48818 19.0572 9.48818L21.7478 9.48818L17.4688 13.7895C17.2397 14.0198 17.2397 14.3932 17.4688 14.6236C17.698 14.8539 18.0695 14.8539 18.2986 14.6236L22.5775 10.3223L22.5775 13.0269C22.5775 13.3527 22.8402 13.6167 23.1642 13.6167C23.4883 13.6167 23.751 13.3527 23.751 13.0269L23.751 8.89839C23.751 8.57265 23.4883 8.30859 23.1643 8.30859Z"
              fill={props.fill || "white"}
            />
          </g>
        </svg>
      )}
    />
  );
}
