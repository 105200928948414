import Icon from "@ant-design/icons";
import React from "react";

export default function ExpandVideoIcon(props: {
  height?: number;
  width?: number;
  fill?: string;
}) {
  return (
    <Icon
      component={() => (
        <svg
          width={props.width || 32}
          height={props.height || 32}
          viewBox={`0 0 ${props.height || 32} ${props.width || 32}`}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle cx="16" cy="16" r="16" fill="#0361F0" />
          <path
            d="M20.8538 15.1075L12.3687 9.51589C11.6451 9.03824 10.666 9.55802 10.666 10.415V21.5841C10.666 22.4551 11.6451 22.9609 12.3687 22.4833L20.8538 16.8917C21.4923 16.4842 21.4923 15.5289 20.8538 15.1074V15.1075Z"
            fill={props.fill || "white"}
          />
        </svg>
      )}
    />
  );
}
