import {
  ILocationCoordinates,
  IMediaCapturedForPreviewAndPost,
  IMediaFileSrc,
} from "common/types/components";
import React, { FC, useState } from "react";
import { CustomButton } from "../CustomButton";
import Modal from "../Modal";
import MediaPreviewAndSubmit from "../MediaControl/MediaPreviewAndSubmit";
import WebcamWrapper from "./components/WebcamWrapper";
import useUploadPicture from "../MediaControl/hooks/useUploadMedia";
import { validateVerificationLocationConfig } from "../../utils/helpers/geolocation";
import { useSelector } from "react-redux";
import { ReduxStoreModel } from "../../types";
import VerificationLocationFailedModal from "../../../modules/verificationTasks/pendingTasks/components/VerificationLocationFailedModal";
import { message } from "antd";

export interface IOnMediaCapture {
  mediaFile: File;
  mediaSrc: string;
}

interface ICameraProps {
  isCameraOpen: boolean;

  setIsCameraOpen(v: boolean): void;
  onCapture(data: IMediaCapturedForPreviewAndPost): void;
  facingMode?: "user" | "environment";
}

const Camera: FC<ICameraProps> = ({
  isCameraOpen,
  setIsCameraOpen,
  onCapture,
  facingMode = "environment",
}) => {
  const {
    verificationLocationValidationConfig: locationValidationConfig,
    verificationOfficerLocationCoordinates: locationCoordinates,
  } = useSelector(
    (store: ReduxStoreModel) => store.verificationLocationValidation
  );

  const [capturedMedia, setCapturedMedia] = useState<IMediaFileSrc>();
  const [isCapturing, setIsCapturing] = useState(false);
  const [isValidatingLocation, setIsValidatingLocation] = useState(false);
  const [locationValidationFailed, setLocationValidationFailed] =
    useState(false);
  const { isSubmittingMedia, handleUpload } = useUploadPicture({
    onUploadSuccess: onCapture,
  });
  const [
    locationValidationFailedErrorMsg,
    setLocationValidationFailedErrorMsg,
  ] = useState<string | undefined>();

  const handleCapture = (data: IMediaFileSrc) => {
    setIsCapturing(false);
    if (data?.mediaFile) {
      setCapturedMedia(data);
    }
  };

  const resetCapturedMedia = () => {
    setCapturedMedia(undefined);
    setIsCapturing(false);
  };

  const onCloseCamera = () => {
    setIsCameraOpen(false);
  };

  const onLocationFetchFailed = (errorMessage?: string) => {
    console.log("Unable to fetch geolocation");
    setIsValidatingLocation(false);
    setLocationValidationFailedErrorMsg(
      errorMessage ??
        "Unable to complete verification process. Please, try again."
    );
    setLocationValidationFailed(true);
  };

  const onLocationValidationFailed = () => {
    console.log("Location validation failed");
    resetCapturedMedia();
    setIsValidatingLocation(false);
    setLocationValidationFailed(true);
  };

  const onLocationValidationPassed = (
    mediaToUpload: IMediaFileSrc,
    geolocation: ILocationCoordinates
  ) => {
    console.log("Location validation passed");
    console.log({ mediaToUpload });
    handleUpload(mediaToUpload, geolocation);
    setIsValidatingLocation(false);
    setLocationValidationFailed(false);
  };

  const handleImageUpload = () => {
    if (!capturedMedia) {
      // TODO : Change the message
      message.error("An error occurred");
      return;
    }
    setIsValidatingLocation(true);
    validateVerificationLocationConfig({
      onLocationValidationPassed: (currentLocation) =>
        onLocationValidationPassed(capturedMedia, currentLocation),
      onLocationFetchFailed,
      onLocationValidationFailed,
      verificationAddressCoordinates: locationCoordinates,
      maximumAllowedDistance: locationValidationConfig.mediaCaptureRadius,
    });
  };

  const retryLocationValidation = () => {
    setIsValidatingLocation(true);
    validateVerificationLocationConfig({
      onLocationValidationPassed: () => onCloseCamera(),
      onLocationFetchFailed,
      onLocationValidationFailed,
      verificationAddressCoordinates: locationCoordinates,
      maximumAllowedDistance: locationValidationConfig.mediaCaptureRadius,
    });
  };

  return (
    <Modal
      open={isCameraOpen}
      hideClose
      hideFooter
      size={"lg"}
      contentWrapperClassName={`p-0 py-0 px-0 rounded-2xl`}
      isBackgroundTransparent
    >
      <>
        <div className="w-full relative rounded-2xl">
          {!capturedMedia ? (
            <WebcamWrapper
              onCaptureStart={setIsCapturing}
              onCaptureEnd={handleCapture}
              onError={onCloseCamera}
              facingMode={facingMode}
            />
          ) : (
            <MediaPreviewAndSubmit
              mediaSrc={capturedMedia?.mediaSrc}
              isSubmitting={isValidatingLocation || isSubmittingMedia}
              onSubmit={handleImageUpload}
              onRetry={resetCapturedMedia}
            />
          )}
          {isCapturing || isSubmittingMedia ? null : (
            <div className="absolute left-0 right-0 -bottom-20 flex items-center justify-center py-2">
              <div className="w-56">
                <CustomButton
                  actionText="Close"
                  disabled={isSubmittingMedia}
                  action={onCloseCamera}
                  antBtnType="ghost"
                  className="w-full"
                />
              </div>
            </div>
          )}
        </div>
        <VerificationLocationFailedModal
          isModalVisible={locationValidationFailed}
          closeModal={onCloseCamera}
          handleRetry={retryLocationValidation}
          locationValidationFailedErrorMsg={locationValidationFailedErrorMsg}
        />
      </>
    </Modal>
  );
};

export default Camera;
