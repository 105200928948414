import { ReduxActionModel } from "common/types";
import {
  WALLET_CASHOUT_PROCESS_RESET,
  IS_FETCHING_WALLET_BALANCE,
  IS_FETCHING_WALLET_TRANSACTIONS,
  IS_PROCESSING_WALLET_CASHOUT,
  WALLET_BALANCE_FETCHED,
  WALLET_CASHOUT_PROCESSED,
  WALLET_TRANSACTIONS_FETCHED,
  WALLET_CASHOUT_ACCOUNT_FETCHED,
  IS_FETCHING_WALLET_CASHOUT_ACCOUNT,
} from "../actions/types";

let initialState = {
  isLoading: false,
  transaction: {},
  cashout: {
    isLoading: false,
    hasProcessedCashout: false,
  },
  accountDetails: {
    isLoading: true,
    data: {},
  },
  balance: {
    isLoading: true,
    data: {
      availableBalance: 0,
      lienBalance: null,
      ledgerBalance: 0,
      customRequestReference: null,
    },
  },
};

export default (state = initialState, action: ReduxActionModel) => {
  switch (action.type) {
    case WALLET_TRANSACTIONS_FETCHED:
      state = { ...state, isLoading: false, transaction: action.payload };
      break;
    case IS_FETCHING_WALLET_TRANSACTIONS:
      state = { ...state, isLoading: action.payload };
      break;
    case WALLET_BALANCE_FETCHED:
      state = { ...state, balance: { isLoading: false, data: action.payload } };
      break;
    case IS_FETCHING_WALLET_BALANCE:
      state = {
        ...state,
        balance: { isLoading: action.payload, data: state.balance.data },
      };
      break;
    case IS_PROCESSING_WALLET_CASHOUT:
      state = {
        ...state,
        cashout: {
          isLoading: action.payload,
          hasProcessedCashout: state.cashout.hasProcessedCashout,
        },
      };
      break;
    case WALLET_CASHOUT_PROCESSED:
      state = {
        ...state,
        cashout: {
          isLoading: false,
          hasProcessedCashout: true,
        },
      };
      break;
    case WALLET_CASHOUT_PROCESS_RESET:
      state = {
        ...state,
        cashout: {
          isLoading: false,
          hasProcessedCashout: false,
        },
      };
      break;
    case WALLET_CASHOUT_ACCOUNT_FETCHED:
      state = {
        ...state,
        accountDetails: {
          isLoading: false,
          data: action.payload,
        },
      };
      break;
    case IS_FETCHING_WALLET_CASHOUT_ACCOUNT:
      state = {
        ...state,
        accountDetails: {
          ...state.accountDetails,
          isLoading: action.payload,
        },
      };
      break;

    default:
      break;
  }
  return state;
};
