import React from "react";

interface ISidebarItemProps {
  children: React.ReactNode;
  text: string;
  isItemActive?: boolean;
  onClick(): void;
}
const SidebarItem = ({
  children,
  text,
  isItemActive,
  onClick,
}: ISidebarItemProps) => {
  return (
    <button
      className={`bg-transparent border-none ${
        isItemActive ? "nav-item_active" : ""
      }`}
      onClick={onClick}
    >
      <div className="bg-transparent border-none" onClick={() => {}}>
        <div className="py-3 px-7">
          <div className="flex space-x-5 items-center justify-start">
            <div>{children}</div>
            <span className="text-sm font-medium">{text}</span>
          </div>
        </div>
      </div>
    </button>
  );
};

export default SidebarItem;
