import { LoggedInUserDetails } from "../../../modules/auth/types/session-model";
import { STORAGE_KEYS } from "../../config/constants";

const getItem = (key: string) => {
  return localStorage.getItem(key) || null;
};

export const setItem = (key: string, value: string) => {
  return localStorage.setItem(key, value);
};
export const removeItem = (key: string) => {
  return localStorage.removeItem(key);
};

export const clearData = () => {
  const deviceId = getDeviceId();

  localStorage.clear();

  setDeviceId(deviceId);
};

export const storeUserDetails = (userDetails: LoggedInUserDetails) => {
  setItem(STORAGE_KEYS.USER_DETAILS_STORAGE_KEY, JSON.stringify(userDetails));
};
export const storeVerificationTaskProgress = (
  verificationTaskProgress: any
) => {
  try {
    setItem(
      STORAGE_KEYS.VERIFICATION_TASK_PROGRESS_STORAGE_KEY,
      JSON.stringify(verificationTaskProgress)
    );
  } catch (error) {
    console.log(error);
  }
};
export const fetchVerificationTaskProgress = () => {
  let details = getItem(STORAGE_KEYS.VERIFICATION_TASK_PROGRESS_STORAGE_KEY);
  if (details) {
    return JSON.parse(details);
  }
  return null;
};

export const clearVerificationTaskProgress = () => {
  removeItem(STORAGE_KEYS.VERIFICATION_TASK_PROGRESS_STORAGE_KEY);
};

export const fetchUserDetails = (): LoggedInUserDetails => {
  let details = getItem(STORAGE_KEYS.USER_DETAILS_STORAGE_KEY);
  if (details) {
    return JSON.parse(details);
  }
  return { access_token: null };
};

export const getDeviceId = (): string => {
  return getItem(STORAGE_KEYS.CLIENT_FINGERPRINT_STORAGE_KEY) || "";
};

export const setDeviceId = (fingerprint: string) => {
  setItem(STORAGE_KEYS.CLIENT_FINGERPRINT_STORAGE_KEY, fingerprint);
};

export const storeUserToken = (token: string | null) => {
  if (!token) token = "";
  return setItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY, token);
};

export const fetchUserToken = () => {
  return getItem(STORAGE_KEYS.CLIENT_TOKEN_STORAGE_KEY);
};

export const setMockLivenessCheckStatus = (status: string) => {
  if (!status) status = "FAILED";
  return setItem(STORAGE_KEYS.LIVENESS_CHECK_STATUS, status);
};

export const fetchMockLivenessCheckStatus = () => {
  return getItem(STORAGE_KEYS.LIVENESS_CHECK_STATUS);
};
