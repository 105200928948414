import React from "react";

const TasksIcon = (props: {
  height?: number;
  width?: number;
  fill?: string;
}) => {
  return (
    <svg
      width={props?.width || 24}
      height={props?.height || 24}
      viewBox={`0 0 ${props?.width || 24} ${props?.height || 24}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8008 2.3999C10.138 2.3999 9.60078 2.93716 9.60078 3.5999C9.60078 4.26264 10.138 4.7999 10.8008 4.7999H13.2008C13.8635 4.7999 14.4008 4.26264 14.4008 3.5999C14.4008 2.93716 13.8635 2.3999 13.2008 2.3999H10.8008Z"
        fill={props?.fill || "#9BA6B9"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.80078 5.9999C4.80078 4.67442 5.8753 3.5999 7.20078 3.5999C7.20078 5.58813 8.81256 7.1999 10.8008 7.1999H13.2008C15.189 7.1999 16.8008 5.58813 16.8008 3.5999C18.1263 3.5999 19.2008 4.67442 19.2008 5.9999V19.1999C19.2008 20.5254 18.1263 21.5999 16.8008 21.5999H7.20078C5.8753 21.5999 4.80078 20.5254 4.80078 19.1999V5.9999ZM16.4493 12.8484C16.9179 12.3798 16.9179 11.62 16.4493 11.1514C15.9807 10.6827 15.2209 10.6827 14.7523 11.1514L10.8008 15.1028L9.24931 13.5514C8.78068 13.0827 8.02088 13.0827 7.55225 13.5514C7.08362 14.02 7.08362 14.7798 7.55225 15.2484L9.95225 17.6484C10.4209 18.1171 11.1807 18.1171 11.6493 17.6484L16.4493 12.8484Z"
        fill={props?.fill || "#9BA6B9"}
      />
    </svg>
  );
};

export default TasksIcon;
