const API_URL = process.env.REACT_APP_API_URL;
const UMS_API_URL = process.env.REACT_APP_UMS_API_URL;

// if (process.env.NODE_ENV === "production") {
// 	API_URL = ;
// }

export const REQUEST_TIMEOUT = 60000;
export const PAGE_SIZE = 10;

export { API_URL };
export { UMS_API_URL };

// local storage keys
export const STORAGE_KEYS = {
  USER_DETAILS_STORAGE_KEY: "field-verification.app.user",
  CLIENT_TOKEN_STORAGE_KEY: "field-verification.app.token",
  VERIFICATION_TASK_PROGRESS_STORAGE_KEY:
    "field-verification.app.verificationTaskProgress",
  CLIENT_FINGERPRINT_STORAGE_KEY: "field-verification.app.device.fingerprint",
  LIVENESS_CHECK_STATUS: "field-verification.app.livenesscheck.status",
};

export const USE_MOCK: boolean =
  process.env.REACT_APP_MOCK && process.env.REACT_APP_MOCK === "true"
    ? true
    : false;
export const AUTHORITY_ACCESS = process.env.REACT_APP_AUTHORITY_ACCESS;
