import { USE_MOCK } from "../../../common/config/constants";
import { ENDPOINTS } from "../../../common/config/endpoints";
import {
  makeAuthorizedRequestWithHeadersAndPayload,
  makeUmsUnauthorizedRequestWithHeadersAndPayload,
  makeUnauthorizedRequestWithHeadersAndPayload,
} from "../../../common/services";
import { LoggedOutUserDetails } from "../types/session-model";
import { mockAuthResponse as Mock } from "./mock";
import { verificationTaskDetailsByClientReference } from "../../verificationTasks/historyTasks/service/mock";

/** log user in **/
export const login = (username: string, password: string): Promise<any> => {
  let endpoint = ENDPOINTS.auth.login;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(Mock.loginResponse);
      }, 3000);
    });
  }

  return makeUnauthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    {
      username,
      password,
    }
  );
};
export const gSuiteLogin = (code: string): Promise<any> => {
  let endpoint = ENDPOINTS.auth.gSuiteLogin;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(Mock.loginResponse);
      }, 3000);
    });
  }

  return makeUnauthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    {
      code,
    }
  );
};

export const loginWithToken = (token: string): Promise<any> => {
  let endpoint = ENDPOINTS.auth.loginWithMoniepointToken;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(Mock.loginResponse);
      }, 3000);
    });
  }

  return makeUnauthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    {
      token,
    }
  );
};

/** log user out **/
export const logout = () => {
  let user: LoggedOutUserDetails = {
    loggedIn: false,
    email: "",
  };

  return user;
};

export const isUnregisteredDeviceError = (err: any): boolean =>
  err.response?.status === 401 && err.response?.data?.shouldRegisterDevice;

export const cosmosLogin = () => {
  let endpoint = ENDPOINTS.auth.cosmosLogin;
  window.location.replace(
    `${endpoint.url}/oauth2/authorize?client_id=${endpoint.clientId}&auth_domain=${endpoint.authDomain}`
  );
};

export const forgotPassword = (username: string): Promise<any> => {
  let endpoint = ENDPOINTS.auth.forgotPassword;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve({ message: "Password reset email sent." });
      }, 3000);
    });
  }

  return makeUmsUnauthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url,
    {
      username,
    }
  );
};

export const taskDetailsByClientReference = (
  clientRequestReference: string
): Promise<any> => {
  let endpoint =
    ENDPOINTS.verificationTasks.verificationsDetailsByRequestReference;

  if (USE_MOCK) {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(verificationTaskDetailsByClientReference);
      }, 3000);
    });
  }

  return makeAuthorizedRequestWithHeadersAndPayload(
    endpoint.method,
    endpoint.url.replace(
      "{clientRequestReference}",
      `${clientRequestReference}`
    )
  );
};
