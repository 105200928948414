import React from "react";

export const DescriptionItem = ({
  label,
  text,
}: {
  label: string;
  text: string;
}) => {
  return (
    <div className="flex flex-col space-y-0 text-left minimum-width-80">
      <p className="text-grey-500 uppercase">{label}</p>
      <p className="text-medium text-black">{text}</p>
    </div>
  );
};
