import React, { FC, ReactNode } from "react";

interface TagProps {
  wrapperStyle?: string;
  text: string;
  bgColor?: string;
  color?: string;
  icon?: ReactNode;
  className?: string;
  onClick?(): void;
}

export const Tag: FC<TagProps> = ({
  bgColor,
  color,
  icon,
  text,
  className,
  wrapperStyle,
  onClick,
}) => {
  return (
    <div className="inline-block">
      <div
        className={`custom-tag-padding ${
          wrapperStyle ||
          `flex items-center justify-center space-x-1 text-xs font-semibold rounded ${bgColor} ${color} ${className}`
        }`}
        onClick={onClick}
      >
        {icon}
        <span className="text-center break-words text-xs font-bold">
          {text}
        </span>
      </div>
    </div>
  );
};
