import { LoadingOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { ButtonHTMLType } from "antd/lib/button/button";
import React, { CSSProperties } from "react";

interface ICustomButtonProps {
  actionText: string;
  disabled?: boolean;
  action?: any;
  loading?: boolean;
  block?: boolean;
  loaded?: boolean;
  size?: "small" | "middle" | "large";
  style?: CSSProperties;
  textStyle?: CSSProperties;
  type?: ButtonHTMLType;
  className?: string;
  antBtnType?: "text" | "link" | "ghost" | "default" | "primary" | "dashed";
  suffixIcon?: JSX.Element | undefined;
  prefixIcon?: JSX.Element | undefined;
}

export function CustomButton({
  actionText,
  loading = false,
  disabled = false,
  block = false,
  action = () => {},
  size = "middle",
  style = {},
  type = "button",
  className = "",
  textStyle = {},
  antBtnType = "primary",
  suffixIcon = undefined,
  prefixIcon = undefined,
}: ICustomButtonProps) {
  return (
    <Button
      size={size}
      disabled={loading || disabled}
      htmlType={type}
      onClick={action}
      style={style}
      block={block}
      type={antBtnType}
      className={
        "btn btn-default flex justify-center items-center " + className
      }
    >
      {prefixIcon}
      <span
        style={textStyle}
        className={`${size === "small" ? "py-2" : "py-3"} text-sm`}
      >
        {" "}
        {actionText}
      </span>
      {suffixIcon}
      {loading && <LoadingOutlined spin />}
    </Button>
  );
}
